.Carousel-swiper {
  width: 100%;
  height: calc(374px + 24px);
  padding: 24px 30px 0;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 32px;
  overflow: hidden;
  .rv-swiper {
    width: 100%;
    height: 100%;
    .rv-indicator__dot--active {
      background-color: var(--my-color);
    }
    // overflow-y: visible;
    // .rv-swiper__indicator {
    //   bottom: -20px;
    //   .rv-indicator__dot {
    //     width: 20px;
    //     height: 4px;
    //     background: #ccc;
    //     border-radius: 0px 0px 0px 0px;
    //     opacity: 1;
    //   }
    //   .rv-indicator__dot--active {
    //     width: 20px;
    //     height: 4px;
    //     background: #18cff7;
    //     border-radius: 0px 0px 0px 0px;
    //     opacity: 1;
    //   }
    // }
    img {
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
}
