@keyframes scrollBackground {
  from {
    background-position: 0 0;
  } /* 动画开始时的位置 */
  to {
    background-position: 0 100%;
  } /* 动画结束时的位置，100%为背景图片的高度，实现向下滚动效果 */
}

.Login {
  width: 100%;
  min-height: 100%;
  // background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // background-image: url(../../assets/images/bg/login-bg@2x.png);
  // background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 0;
  background-image: url("../../assets/images/login/tpbj@2x.png"); /* 背景图片URL */
  // background-repeat: repeat-y; /* 如果图片不够高，可设置为重复填充垂直方向 */
  animation: scrollBackground 20s linear infinite; /* 应用动画，duration为动画持续时间，如5s表示5秒 */

  .Login-logo {
    width: 260px;
    height: 282px;
    margin-bottom: 36px;
  }
  .Login-slogan {
    width: 544px;
    height: 128px;
    margin-bottom: 62px;
  }
  .Login-form {
    width: 630px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
    .Login-form-row {
      position: relative;
      width: 100%;
      height: 116px;
      margin-bottom: 48px;
      input {
        padding-left: 100px;
        padding-right: 70px;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        font-size: 32px;
        color: #ffffff;
        background-color: rgba($color: #909094, $alpha: 0.6);
        border-radius: 200px 200px 200px 200px;
        &::placeholder {
          color: #ffffff;
        }
      }
      .Login-form-img {
        width: 32px;
        height: 40px;
        position: absolute;
        left: 40px;
        top: 38px;
      }
      .Login-eye-icon {
        width: 48px;
        height: 48px;
        position: absolute;
        right: 20px;
        top: 34px;
      }
    }
    .Login-form-tip {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 28px;
      color: #ffffff;
      a {
        color: #ffffff;
      }
    }
    .Login-form-btn {
      margin-top: 48px;
      width: 630px;
      height: 96px;
      background: linear-gradient(270deg, #fb3197 0%, #fc7ebe 100%);
      border-radius: 200px 200px 200px 200px;
      line-height: 96px;
      text-align: center;
      font-weight: bold;
      font-size: 36px;
      color: #fff;
    }
    .Login-form-agreement {
      display: flex;
      align-items: center;
      justify-content: center;
      .Login-check {
        width: 36px;
        height: 36px;
        border: 2px solid #707070;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        border-radius: 50%;
        &.Login-check-active {
          &::after {
            display: block;
            content: "";
            width: 18px;
            height: 18px;
            background-color: var(--my-color);
            border-radius: 50%;
          }
        }
      }
      margin-top: 32px;
      font-size: 24px;
      color: #ffffff;
      a {
        color: #ffffff;
      }
    }
  }

  .rv-checkbox__icon--checked .rv-icon {
    background-color: #333;
    border-color: #333;
  }
}
