.VipPage {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  .VipPage-head {
    position: sticky;
    top: 0;
    background-color: #fff;
  }
  .VipPage-body {
    width: 100%;
    padding: 20px 32px;
    box-sizing: border-box;
    .VipPage-list-item {
      margin-bottom: 32px;
      .VipPage-list-title {
        font-size: 32px;
        font-weight: bold;

        margin-bottom: 20px;
      }
      .VipPage-list-desc {
        font-weight: 400;
        font-size: 28px;
        color: #333333;
        line-height: 44px;
        text-indent: 2em;
      }
      .VipPage-list-content {
        font-weight: 400;
        font-size: 28px;
        color: #333333;
        line-height: 44px;
        margin-bottom: 10px;
        span {
          font-weight: bold;
        }
      }
    }
  }
}
