.UserItem {
  width: 100%;
  border-radius: 32px;
  padding: 24px;
  box-sizing: border-box;
  background-color: #fff;
  margin-bottom: 48px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  .UserItem-cover {
    width: 150px;
    margin-right: 26px;
    img {
      width: 150px;
      height: auto;
      max-height: 200px;
      border-radius: 16px;
    }
  }
  .UserItem-info {
    flex: 1;
    position: relative;
    z-index: 10;
    .UserItem-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .UserItem-name {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 32px;
        color: #22172a;
        margin-bottom: 8px;
        .UserItem-sex {
          width: 36px;
          height: 36px;
          margin-left: 12px;
        }
      }
      .UserItem-collect {
        width: 56px;
        height: 56px;
        background: #fff1f6;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 34px;
        }
      }
    }
    .UserItem-labels {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .UserItem-label-item {
        height: 48px;
        background: #faf2f6;
        border-radius: 8px;
        padding: 8px;
        box-sizing: border-box;
        font-size: 22px;
        color: #080715;
        margin-right: 12px;
        margin-bottom: 8px;
      }
    }
    .UserItem-intro {
      width: 100%;
      margin-top: 12px;
      margin-bottom: 24px;
      font-size: 26px;
      color: #333333;
    }
    .UserItem-imgs {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      .UserItem-img-item {
        width: 144px;
        height: 144px;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 144px;
          height: 144px;
          border-radius: 16px;
        }
      }
    }
  }
  .UserItem-logo {
    position: absolute;
    top: 24px;
    right: 82px;
    width: 72px;
    height: auto;
    z-index: 0;
  }
}
