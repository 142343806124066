.Vip {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-image: url("../../assets/images/vip/bg@2x.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 0;
  padding-top: 696px;
  padding-bottom: var(--my-tab-height);
  .Vip-body {
    width: 100%;
    min-height: calc(100vh - var(--my-tab-height) - 696px);
    background: #ffffff;
    border-radius: 32px 32px 0px 0px;
    .Vip-content {
      position: relative;
      top: -88px;
      padding-bottom: 88px;
    }
    .vip-card {
      width: 686px;
      height: 322px;
      margin: 0 auto;
      margin-bottom: 32px;
      position: relative;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100;
      }
      .vip-card-name {
        position: absolute;
        left: 112px;
        top: 42px;
        font-weight: bold;
        font-size: 38px;
        color: #fc56a8;
      }
      .vip-card-price {
        position: absolute;
        left: 50px;
        top: 116px;
        display: flex;
        align-items: center;
        .vip-card-price-cur {
          font-weight: bold;
          font-size: 40px;
          color: #ff55ab;
        }
        .vip-card-price-og {
          margin-left: 26px;
          font-weight: bold;
          font-size: 28px;
          color: #ffffff;
          line-height: 52px;
          text-align: left;
          font-style: normal;
          text-decoration-line: line-through;
          text-transform: none;
        }
      }
      .vip-card-tip {
        position: absolute;
        left: 50px;
        bottom: 40px;
        font-weight: bold;
        font-size: 28px;
        color: #ffffff;
      }
      .vip-card-btn {
        position: absolute;
        right: 74px;
        bottom: 28px;
        width: 168px;
        height: 60px;
        background: linear-gradient(274deg, #fc508f 0%, #ff82bf 100%);
        border-radius: 8px 8px 8px 8px;
        text-align: center;
        line-height: 60px;
        font-weight: bold;
        font-size: 30px;
        color: #ffffff;
      }
    }
    .Mine-vip {
      position: relative;
      z-index: 10;
      margin: 0 auto;
      margin-bottom: 32px;
      width: 686px;
      height: 322px;
      background-image: url("../../assets/images/vip/vip_card@2x.png");
      background-size: cover;
      background-repeat: no-repeat;
      .Mine-vip-name {
        position: absolute;
        left: 112px;
        top: 40px;
        font-weight: bold;
        font-size: 40px;
        color: #fc56a8;
      }
      .Mine-vip-date {
        position: absolute;
        top: 48px;
        right: 24px;
        font-weight: bold;
        font-size: 28px;
        color: #ff55ab;
      }
      .Mine-vip-info {
        position: absolute;
        left: 50px;
        bottom: 64px;
        .Mine-vip-info-item {
          font-size: 26px;
          color: #b3226c;
          line-height: 42px;
          display: flex;
          align-items: center;
          &::before {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #b3226c;
            margin-right: 10px;
          }
        }
      }
    }
    .vip-equity {
      width: 100%;
      padding: 0 32px;
      box-sizing: border-box;
      position: relative;

      .vip-equity-title {
        font-weight: bold;
        font-size: 36px;
        color: #333333;
        margin-bottom: 32px;
      }
      .vip-equity-list {
        width: 100%;
        .vip-equity-item {
          width: 100%;
          padding: 0 32px;
          margin-bottom: 32px;
          box-sizing: border-box;
          height: 136px;
          background: #ffe1f0;
          border-radius: 28px 28px 28px 28px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .vip-equity-item-img {
            width: 92px;
            height: 92px;
            margin-right: 32px;
          }
          .vip-equity-info {
            flex: 1;
            .vip-equity-info-title {
              font-weight: bold;
              font-size: 32px;
              color: #ff55ab;
              margin-bottom: 8px;
            }
            .vip-equity-info-desc {
              font-size: 28px;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
