.Authentication {
  width: 100%;
  height: 100vh;
  background: #faf2f7;
  .Authentication-form {
    margin-top: 32px;
    .row-input-wrap {
      width: 100%;
      height: 124px;
      padding: 0 30px;
      box-sizing: border-box;
      margin-bottom: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      .row-input-label {
        width: 130px;
        font-size: 32px;
        font-weight: bold;
        color: #666;
        margin-right: 40px;
      }
      input {
        flex: 1;

        font-size: 32px;
        font-weight: bold;
        color: #000000;
      }
    }
    .Authentication-btn {
      display: block;
      margin: 0 auto;
      margin-top: 200px;
      width: 556px;
      height: 84px;
      line-height: 84px;
      text-align: center;
      background: var(--my-color);
      border-radius: 200px 200px 200px 200px;
      font-size: 32px;
      color: #ffffff;
    }
  }
}
