.Mine {
  width: 100%;
  min-height: 100vh;
  padding-bottom: var(--my-tab-height);
  box-sizing: border-box;
  position: relative;
  background: #faf2f7;
  .Mine-head {
    width: 100%;
    height: 468px;
    position: relative;
    background-image: url("../../assets/images/mine/wd_bj@2x.png");
    background-size: cover;
    background-repeat: no-repeat;
    .Mine-head-wlogo {
      position: absolute;
      z-index: 2;
      right: 52px;
      top: 44px;
      width: 182px;
      height: 196px;
      background-image: url("../../assets/images/w_logo@2x.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .Mine-head-bg {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/images/mine/bg1.png");
      background-size: cover;
      background-repeat: no-repeat;
      .Mine-tip {
        position: absolute;
        left: 182px;
        bottom: 48px;
        .Mine-tip-title {
          font-weight: bold;
          font-size: 32px;
          color: #333333;
          margin-bottom: 4px;
        }
        .Mine-tip-text {
          font-size: 28px;
          color: #333333;
        }
      }
    }
    .Mine-info {
      position: relative;
      z-index: 10;
      width: 100%;

      padding: 52px 64px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .Mine-info-cover {
        width: 180px;
        height: 180px;
        margin-right: 36px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .Mine-info-right {
        flex: 1;
        padding-top: 32px;
        .Mine-info-name {
          width: 100%;
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 40px;
          color: #ffffff;
          margin-bottom: 20px;
          .Mine-sex {
            width: 40px;
            height: 40px;
            margin-left: 24px;
          }
        }
        .Mine-info-phone {
          font-size: 26px;
          color: #ffffff;
        }
      }
    }
  }
  .Mine-vip {
    position: relative;
    z-index: 10;
    margin: 0 auto;
    margin-top: -160px;
    width: 686px;
    height: 322px;
    background-image: url("../../assets/images/vip/vip_card@2x.png");
    background-size: cover;
    background-repeat: no-repeat;
    .Mine-vip-name {
      position: absolute;
      left: 112px;
      top: 40px;
      font-weight: bold;
      font-size: 40px;
      color: #fc56a8;
    }
    .Mine-vip-date {
      position: absolute;
      top: 48px;
      right: 24px;
      font-weight: bold;
      font-size: 28px;
      color: #ff55ab;
    }
    .Mine-vip-info {
      position: absolute;
      left: 50px;
      bottom: 64px;
      .Mine-vip-info-item {
        font-size: 26px;
        color: #b3226c;
        line-height: 42px;
        display: flex;
        align-items: center;
        &::before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #b3226c;
          margin-right: 10px;
        }
      }
    }
  }

  .Mine-body {
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
  }
  .Mine-nav {
    width: 100%;
    margin-top: 32px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 16px;
    .Mine-nav-item {
      width: 100%;
      height: 110px;
      padding-left: 28px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .Mine-nav-item-img {
        width: 48px;
        height: 48px;
        margin-right: 12px;
      }
      .Mine-nav-item-content {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 28px;
        box-sizing: border-box;
        font-size: 32px;
        color: #333333;
        border-bottom: 1px solid #f6f6f6;

        .Mine-nav-item-more {
          width: 40px;
          height: 40px;
        }
      }
      &:last-of-type {
        .Mine-nav-item-content {
          border-bottom: none;
        }
      }
    }
  }
  .Mine-logout {
    display: block;
    margin: 0 auto;
    margin-top: 32px;
    width: 590px;
    height: 96px;
    background: var(--my-color);
    border-radius: 200px 200px 200px 200px;
    text-align: center;
    line-height: 96px;
    font-weight: bold;
    font-size: 32px;
    color: #ffffff;
  }
  .logout-slot {
    width: 100%;
    height: 96px;
  }
}
