.Order {
  width: 100%;
  background: #faf2f7;
  .Order-bar {
    position: sticky;
    top: 0;
    z-index: 100;
    background: #faf2f7;
  }
  .Order-list {
    width: 100%;
    padding: 32px 30px 0;
    box-sizing: border-box;

    .Order-item {
      width: 100%;
      padding: 32px 20px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      margin-bottom: 32px;
      .Order-item-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:not(:last-of-type) {
          margin-bottom: 28px;
        }
        .Order-item-name {
          font-weight: bold;
          font-size: 32px;
          color: #333333;
        }
        .Order-item-num {
          display: flex;
          align-items: center;
          font-size: 28px;
          color: #999999;
          img {
            width: 48px;
            height: 48px;
          }
        }
        .Order-item-date {
          font-size: 28px;
          color: #999999;
        }
        .Order-item-price {
          font-weight: bold;
          font-size: 40px;
          color: #f81037;
        }
      }
    }
  }

  .rv-pull-refresh {
    min-height: calc(100vh - var(--my-nav-height));
    .rv-pull-refresh__track {
      min-height: calc(100vh - var(--my-nav-height));
      background: #faf2f7;
    }
  }
}
