.Agency {
  width: 100%;
  min-height: 100vh;
  background-color: #ffb6dc;
  font-size: 0;
  .Agency-head {
    position: sticky;
    top: 0;
    background-color: #ffb6dc;
  }
  .Agency-img {
    width: 100%;
    height: auto;
  }
}
