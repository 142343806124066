.About {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  .About-head {
    position: sticky;
    top: 0;
    background-color: #fff;
  }
  .About-body {
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    h2 {
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 32px;
    }
    p {
      font-weight: 400;
      font-size: 28px;
      color: #333333;
      line-height: 44px;
      text-indent: 2em;
    }
    .About-bottom {
      margin-bottom: 32px;
      font-weight: 400;
      font-size: 28px;
      color: #333333;
      line-height: 44px;
      width: 100%;
      text-align: right;
    }
  }
}
