@mixin absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

// flex 横向居中
@mixin flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

// flex 纵向居中
@mixin flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// 蒙版
@mixin masking {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.3);
  z-index: -1;
}

//文本单行超出省略号
@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

@mixin ellipsis-line($line: 2) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  overflow: hidden;
}

// 隐藏滚动条
@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
  }
}

// 毛玻璃
@mixin glassmorphism {
  // position: absolute;
  /* 重点，不能让背景大过于展示的图片 */
  @include absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  filter: blur(8px);
  // background-image: url();
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
