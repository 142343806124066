.NavBar {
  position: relative;
  z-index: 100;
  width: 100%;
  height: var(--my-nav-height);
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  line-height: 130px;
  text-align: center;
  // background: #111315;
  // background-color: transparent;
  // transition: background-color 0.3s;
  .NavBar-back {
    position: absolute;
    z-index: 0;
    left: 30px;
    top: 40px;
    width: 48px;
    height: 48px;
  }
  .NavBar-right {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}

// .NavBar-bg {
//   background-image: url("../../assets/images/bg/nav@2x.png");
//   background-repeat: no-repeat;
//   background-size: cover;
// }
// .NavBar-bg1 {
//   background-image: url("../../assets/images/bg/invite@2x.png");
//   background-repeat: no-repeat;
//   background-size: cover;
// }
.wbg {
  color: #333;
  background-color: transparent;
}
.bg1 {
  background: transparent;
}
