.EditPassword {
  width: 100%;
  height: 100vh;
  background: #faf2f7;

  .EditPassword-form {
    width: 690px;
    // background-color: #fff;
    border-radius: 16px;
    margin: 0 auto;
    padding-top: 150px;
    box-sizing: border-box;
  }

  .EditPassword-btn {
    width: 112px;
    height: 60px;
    background: var(--my-color);
    border-radius: 8px 8px 8px 8px;
    font-size: 32px;
    line-height: 60px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    &.EditPassword-btn-disabled {
      background: #e1e1e1;
      color: #b7b8b8;
    }
  }

  .row-input {
    box-sizing: border-box;
    margin-bottom: 40px;
    border-radius: 16px 16px 16px 16px;
  }

  .row-input-wrap {
    width: 670px;
    height: 124px;
    margin: 0 auto;
    background: #fff;
    // background: #1d2322;
    border-radius: 16px;

    // border-bottom: 1px solid #eee;
    box-sizing: border-box;

    position: relative;

    input {
      background: #fff;
      padding-left: 100px;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      font-size: 28px;
      font-weight: bold;
      color: #333;

      box-sizing: border-box;
      &::placeholder {
        color: #071d3a;
      }
    }

    .row-input-after {
      width: 48px;
      height: 48px;
      position: absolute;
      bottom: 38px;
      right: 32px;
    }
    .row-input-bef {
      width: 48px;
      height: 48px;
      position: absolute;
      bottom: 38px;
      left: 32px;
    }
  }
  .EditPassword-btn {
    display: block;
    margin: 0 auto;
    margin-top: 200px;
    width: 556px;
    height: 84px;
    line-height: 84px;
    text-align: center;
    background: var(--my-color);
    border-radius: 200px 200px 200px 200px;
    font-size: 32px;
    color: #ffffff;
  }
}
