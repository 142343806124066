.Privacy {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  .Privacy-head {
    position: sticky;
    top: 0;
    background-color: #fff;
  }
  .Privacy-detail {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
}
