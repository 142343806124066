.Profile {
  width: 100%;
  min-height: 100vh;
  background: #faf2f7;
  padding-top: 22px;
  padding-bottom: 120px;
  box-sizing: border-box;
  .Profile-nav {
    background: #faf2f7;
  }
  .Profile-body {
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    .Profile-tip {
      width: 100%;
      padding: 16px 32px;
      box-sizing: border-box;
      background: var(--my-color);
      border-radius: 16px 16px 16px 16px;
      margin-bottom: 32px;
      .Profile-tip-top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .Profile-tip-title {
          font-weight: bold;
          font-size: 36px;
          color: #ffffff;
        }
        .Profile-tip-more {
          display: flex;
          align-items: center;
          font-size: 32px;
          color: #333333;
          img {
            width: 48px;
            height: 48px;
          }
        }
      }
      .Profile-tip-desc {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
  .Profile-avatar {
    width: 100%;
    height: 336px;
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    position: relative;
    .Profile-avatar-input {
      width: 148px;
      height: 148px;
      margin-bottom: 44px;
      position: relative;
      border-radius: 50%;
      border: 1px solid #ff55ab;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
      .Profile-avatar-input-label {
        position: absolute;
        bottom: -20px;
        left: 50%;
        margin-left: -66px;
        width: 132px;
        height: 40px;
        border-radius: 200px;
        background-color: rgba($color: #000000, $alpha: 0.3);
        text-align: center;
        line-height: 40px;
        font-size: 22px;
        color: #ffffff;
        z-index: 1;
      }
      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        opacity: 0;
      }
    }
    .Profile-avatar-tip {
      font-size: 26px;
      color: var(--my-color);
    }
    .Profile-avatar-l1 {
      position: absolute;
      left: 54px;
      top: 28px;
      width: 84px;
      height: auto;
    }
    .Profile-avatar-l2 {
      position: absolute;
      right: 46px;
      bottom: 94px;
      width: 140px;
      height: auto;
    }
  }
  .Profile-section {
    width: 100%;
    margin-bottom: 32px;
    .Profile-section-title {
      font-weight: bold;
      font-size: 32px;
      color: #333333;
      span {
        font-size: 24px;
        color: #999999;
      }
    }
    .Profile-section-desc {
      font-size: 26px;
      color: #999999;
      margin-top: 10px;
      margin-bottom: 24px;
    }
    .Profile-section-body {
      width: 100%;
      background-color: #fff;
      border-radius: 16px;
      overflow: hidden;
    }
  }

  .Profile-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px 32px;
    box-sizing: border-box;
    background-color: #fff;
    .Profile-bottom-btn {
      width: 690px;
      height: 82px;
      background: var(--my-color);
      border-radius: 200px;
      text-align: center;
      line-height: 82px;
      font-weight: bold;
      font-size: 32px;
      text-align: center;
      color: #fff;
    }
  }
  .row-value {
    color: #333333;
  }
  .rv-field__label {
    --rv-field-label-color: #333;
  }
  .rv-input {
    --rv-input-placeholder-text-color: #969799;
  }
}
