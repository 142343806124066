.User {
  background: #ffffff;
  min-height: 100vh;
  padding-bottom: 120px;
  .User-cover {
    width: 100%;
    height: 720px;
    position: relative;
    margin-bottom: 32px;
    .User-back {
      position: absolute;
      left: 32px;
      top: 32px;
      width: 64px;
      height: 64px;
    }
    .User-cover-bottom {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 18px;
      padding: 0 32px;

      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .User-location {
        width: fit-content;
        min-width: 198px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        padding: 0 16px;
        box-sizing: border-box;
        border-radius: 200px;
        margin-right: 32px;
        background: rgba($color: #000000, $alpha: 0.3);
        font-size: 24px;
        color: #ffffff;
        img {
          width: 36px;
          height: 36px;
          margin-right: 10px;
        }
      }
      .User-imgs {
        flex: 1;
        height: 92px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        .User-imgs-list {
          width: fit-content;

          display: flex;
          justify-content: flex-end;
          flex-wrap: nowrap;
          .User-imgs-item {
            width: 92px;
            height: 92px;
            margin-right: 10px;
            flex-shrink: 0;
          }
          img {
            border-radius: 8px;
          }
        }
      }
    }
  }
  .User-body {
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    .User-head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      position: relative;
      .User-head-collect {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        color: #fe377f;
        margin-left: 32px;
        .User-collect {
          width: 56px;
          height: 56px;
          background: #fff1f6;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 34px;
          }
        }
      }
      .User-head-left {
        flex: 1;
        .User-head-top {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          .User-head-name {
            font-weight: bold;
            font-size: 48px;
            color: #333333;
          }
          .User-head-sex {
            width: 32px;
            height: 32px;
            margin-left: 16px;
          }
          .User-head-code {
            font-size: 26px;
            color: #999999;
            margin-left: 24px;
          }
          .User-head-copy {
            width: 48px;
            height: 48px;
          }
        }
        .User-head-desc {
          width: 100%;
          .User-head-desc-item {
            font-size: 26px;
            color: #333333;
            &::after {
              content: "";
              display: inline-block;
              width: 1px;
              height: 24px;
              position: relative;
              top: 2px;
              background-color: #d6d6d6;
              margin-left: 8px;
              margin-right: 8px;
            }
            &:last-of-type {
              &::after {
                content: "";
                display: none;
              }
            }
          }
        }
      }
      .User-logo {
        position: absolute;
        right: 64px;
        bottom: -60px;
        width: 104px;
        height: auto;
      }
    }
    .User-section {
      width: 100%;
      margin-bottom: 32px;
      position: relative;
      .User-section-title {
        font-weight: bold;
        font-size: 32px;
        color: #333333;
        margin-bottom: 22px;
      }
      .User-base {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .User-base-item {
          width: 50%;
          display: flex;
          align-items: center;

          .User-base-item-label {
            font-size: 28px;
            color: #d0b0be;
            margin-right: 10px;
            width: 116px;
          }
          .User-base-item-value {
            flex: 1;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 28px;
            color: #333333;
          }
        }
        .User-base-item-long {
          width: 100%;
          .User-base-item-label {
            width: 150px;
          }
        }
      }
      .User-intro {
        width: 100%;
        padding: 24px 30px;
        box-sizing: border-box;
        background: #f8e7f6;
        border-radius: 16px;
        font-size: 28px;
        color: #333333;
        line-height: 44px;
        position: relative;
        z-index: 10;
      }
      .User-desc {
        font-size: 28px;
        color: #333333;
        line-height: 44px;
        position: relative;
        z-index: 10;
      }
      .User-section-logo {
        position: absolute;
        z-index: 1;
        width: 316px;
        height: 340px;
        background-image: url("../../assets/images/fens11@2x.png");
        background-size: cover;
        background-repeat: no-repeat;
        bottom: -340px;
        right: 0;
      }
    }
  }
  .User-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px 32px;
    box-sizing: border-box;
    background-color: #fff;
    .User-bottom-btn {
      width: 690px;
      height: 82px;
      background: var(--my-color);
      border-radius: 200px;
      text-align: center;
      line-height: 82px;
      font-weight: bold;
      font-size: 32px;
      text-align: center;
      color: #fff;
    }
  }
  .Pay-bottom {
    background-color: #fff;
    position: fixed;
    z-index: 100;
    bottom: 0;
    width: 100%;
    padding: 20px 32px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .Pay-bottom-left {
      flex: 1;
      .Pay-price {
        font-weight: bold;
        font-size: 36px;
        color: #ff3a3a;
        .Pay-price-label {
          font-weight: bold;
          font-size: 28px;
          color: #333333;
          margin-right: 10px;
        }
      }
      .Pay-am {
        display: flex;
        align-content: center;
        font-size: 24px;
        color: #323334;
        .rv-checkbox__label {
          margin-left: 4px;
        }

        .Pay-am-link,
        a {
          color: #0e81f5;
        }
      }
    }

    .Pay-bottom-btn {
      width: 270px;
      height: 72px;
      background: #ff55ab;
      border-radius: 200px 200px 200px 200px;
      font-weight: bold;
      font-size: 32px;
      color: #ffffff;
      text-align: center;
      line-height: 72px;
    }
  }
}
