.Heartbeat {
  width: 100%;
  background: #faf2f7;
  .Heartbeat-bar {
    position: sticky;
    top: 0;
    z-index: 100;
    background: #faf2f7;
  }
  .Heartbeat-list {
    width: 100%;
    padding: 32px 30px 0;
    box-sizing: border-box;
  }

  .rv-pull-refresh {
    min-height: calc(100vh - var(--my-nav-height));
    .rv-pull-refresh__track {
      min-height: calc(100vh - var(--my-nav-height));
      background: #faf2f7;
    }
  }
}
