.Pay {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-image: url("../../assets/images/pay/bg@2x.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 0;
  padding-top: 430px;
  // padding-bottom: 120px;
  .Pay-back {
    width: 64px;
    height: 64px;
    position: absolute;
    left: 32px;
    top: 36px;
  }
  // padding-bottom: var(--my-tab-height);
  .Pay-body {
    width: 100%;
    min-height: calc(100vh - 430px);
    background: #ffeaf3;
    border-radius: 32px 32px 0px 0px;
    .Pay-card {
      width: 686px;
      height: 322px;
      margin: 0 auto;
      margin-bottom: 32px;
      position: relative;
      top: -88px;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100;
      }
      .Pay-card-name {
        position: absolute;
        left: 112px;
        top: 42px;
        font-weight: bold;
        font-size: 38px;
        color: #fc56a8;
      }
      .Pay-vip-info {
        position: absolute;
        left: 50px;
        bottom: 14px;
        .Pay-vip-info-item {
          font-size: 26px;
          color: #b3226c;
          line-height: 42px;
          display: flex;
          align-items: center;
          &::before {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #b3226c;
            margin-right: 10px;
          }
        }
      }
      .Pay-card-price {
        position: absolute;
        left: 50px;
        bottom: 170px;
        display: flex;
        align-items: center;
        .Pay-card-price-cur {
          font-weight: bold;
          font-size: 40px;
          color: #ff55ab;
        }
        .Pay-card-price-og {
          margin-left: 26px;
          font-weight: bold;
          font-size: 28px;
          color: #ffffff;
          line-height: 52px;
          text-align: left;
          font-style: normal;
          text-decoration-line: line-through;
          text-transform: none;
        }
      }
    }
    .Pay-content {
      width: 100%;
      padding: 0 32px;
      box-sizing: border-box;
    }
    .Pay-section {
      position: relative;
      top: -88px;
      width: 100%;
      margin-bottom: 48px;
      .Pay-section-head {
        width: 100%;
        height: 96px;
        background: linear-gradient(180deg, #ffb0d6 0%, #ff70b9 100%);
        border-radius: 16px 16px 0px 0px;
        display: flex;
        align-items: center;
        padding: 0 32px;
        box-sizing: border-box;
        .Pay-section-logo {
          width: 54px;
          height: 58px;
          margin-right: 10px;
        }
        .Pay-section-title {
          font-weight: bold;
          font-size: 32px;
          color: #ffffff;
        }
      }
      .Pay-section-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #fff4fa;
        border-radius: 0px 0px 16px 16px;
        padding: 38px 0px;
        box-sizing: border-box;
        p {
          font-size: 24px;
          color: #333333;
          margin-bottom: 20px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        .Pay-section-wx {
          margin-top: 40px;
          width: 544px;
          height: 84px;
        }
        .Pay-section-td {
          margin-top: 40px;
          width: 488px;
          height: 32px;
        }
        .Pay-section-zu {
          width: 632px;
          height: 696px;
        }
      }
    }
    .Pay-slot {
      width: 100%;
      height: 50px;
    }
  }
  .Pay-bottom {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 20px 32px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .Pay-bottom-left {
      flex: 1;
      .Pay-price {
        font-weight: bold;
        font-size: 36px;
        color: #ff3a3a;
        .Pay-price-label {
          font-weight: bold;
          font-size: 28px;
          color: #333333;
          margin-right: 10px;
        }
      }
      .Pay-am {
        display: flex;
        align-content: center;
        font-size: 24px;
        color: #323334;
        .rv-checkbox__label {
          margin-left: 4px;
        }

        .Pay-am-link,
        a {
          color: #0e81f5;
        }
      }
    }

    .Pay-bottom-btn {
      width: 270px;
      height: 72px;
      background: #ff55ab;
      border-radius: 200px 200px 200px 200px;
      font-weight: bold;
      font-size: 32px;
      color: #ffffff;
      text-align: center;
      line-height: 72px;
    }
  }
}
