.Index {
  padding-bottom: var(--my-tab-height);

  .Index-body {
    box-sizing: border-box;
    .Index-filter {
      z-index: 100;
      // position: sticky;
      // top: 0;
      // left: 0;
      background: var(--my-bg-color);
      width: 100%;
      height: 80px;
      padding: 0 30px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .Index-filter-type {
        display: flex;
        align-items: center;

        .Index-filter-type-item {
          line-height: 56px;
          margin-right: 56px;
          font-size: 40px;
          color: #4b164c;
          font-weight: normal;
          &.active-type-item {
            font-weight: bold;
          }
        }
      }
      .Index-filter-btn {
        width: 120px;
        height: 52px;
        border-radius: 200px;
        background: var(--my-color);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 24px;
        color: #fff;
        img {
          width: 16px;
          height: 12px;
          margin-left: 12px;
        }
      }
    }
    .Index-list {
      width: 100%;
      padding: 32px 30px 0;
      box-sizing: border-box;
    }
  }
  .Index-ibg {
    width: 100%;
    height: auto;
  }

  .rv-pull-refresh {
    min-height: calc(100vh - var(--my-tab-height));
    .rv-pull-refresh__track {
      min-height: calc(100vh - var(--my-tab-height));
      background: var(--my-bg-color);
    }
  }
}
