@import "../../assets/style/commom.scss";

.TabBar {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  height: var(--my-tab-height);
  padding-bottom: env(safe-area-inset-bottom);
  background: #fff;
  border-top: 1px solid #ececec;
  // border-radius: 32px 32px 0px 0px;

  @include flex-row;
  justify-content: space-around;
  .TabBar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 80px;

    .TabBar-item-img {
      z-index: 3;
      width: 48px;
      height: 48px;
    }
    .TabBar-item-text {
      font-size: 24px;
      font-weight: 400;
      color: #999;
      transform: scale(0.9);
    }
  }
  .TabBar-item-active {
    .TabBar-item-text {
      color: var(--my-color);
      font-weight: bold;
    }
  }
  .TabBar-item-release {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: var(--my-color);
    font-size: 60px;
    text-align: center;
    color: #fff;
    text-align: center;
    overflow: hidden;
  }
}
