.SearchFilter {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  height: fit-content;
  top: 100%;
  z-index: 100;
  .SearchFilter-body {
    width: 100%;
    padding: 32px 30px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 0px 0px 32px 32px;
    .SearchFilter-body-row {
      width: 100%;
      padding: 10px 32px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;
      & > span {
        width: 100px;
        font-size: 32px;
        color: #333333;
        margin-right: 20px;
      }
      .SearchFilter-body-row-content {
        flex: 1;
        .SearchFilter-slider {
          width: 100%;
          display: flex;
          align-items: center;
          .SearchFilter-slider-value {
            width: 50px;
            font-size: 30px;
            color: #333333;
          }
          .SearchFilter-slider-body {
            flex: 1;
            padding: 0 30px;
            box-sizing: border-box;
          }
        }
      }
    }
    .SearchFilter-btns {
      padding-top: 32px;
      border-top: 1px solid #ddd;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .SearchFilter-reset {
        width: 260px;
        height: 70px;

        border-radius: 200px 200px 200px 200px;
        border: 1px solid #707070;
        font-weight: bold;
        font-size: 32px;
        color: #333333;
        line-height: 70px;
        text-align: center;
      }
      .SearchFilter-submit {
        width: 260px;
        height: 70px;
        background: var(--my-color);
        border-radius: 200px 200px 200px 200px;

        font-weight: bold;
        font-size: 32px;
        color: #ffffff;
        line-height: 70px;
        text-align: center;
      }
    }
  }
}
