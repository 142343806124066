

* {
  margin: 0;
  padding: 0;
}
body {
  font-family: PingFang SC, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

code {
  font-family: PingFang SC, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

ol, ul {
  list-style: none;
  }

a {
  text-decoration: none;
}



input,button {
  border: none;
  outline: none;
}


:root {
  --my-nav-height:130px;
  --my-bg-color:#FFE1F0;
  --my-color:#FF55AB;
  --my-tab-height:104px;
}

.tab-slot {
  height:104px;
}

.richtext-detail img{
  width: 100%;
  vertical-align: top;
}

